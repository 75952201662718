import React from "react";
import { Link, useLocation } from "react-router-dom";

import Icon2 from "../../../../../assets/images/logo2.png";
import { Icon } from "@iconify/react";
import _, { debounce } from "lodash";

const Nav = (props) => {
  const { search } = useLocation();
  const { smallDevice } = props;
  var mySidebar = document.getElementById("mySidebar");

  function w3_open() {
    if (mySidebar.style.display === "block") {
      mySidebar.style.display = "none";
    } else {
      mySidebar.style.display = "block";
    }
  }

  // Close the sidebar with the close button
  function w3_close() {
    mySidebar.style.display = "none";
  }

  return (
    <div>
      <div
        className="w3-top"
        style={{
          fontFamily: "noto sans tc,Arial,sans-serif",
          fontWeight: "600",
        }}
      >
        <div
          className="w3-bar w3-black w3-card"
          id="myNavbar"
          style={{
            alignItems: "center",
            minHeight: "60px",
            fontSize: "15px",
          }}
        >
          <a
            href="https://playreal.com.tw/index.html"
            className="w3-bar-item  w3-wide w3-hide-small"
            style={{ width: "200px" }}
          >
            <img style={{ width: "100%" }} src={Icon2} />
          </a>
          <div className="w3-display-middle w3-hide-small">
            <a
              href="https://playreal.com.tw/plans.html"
              className="w3-bar-item w3-button w3-center-a"
              style={{ padding: "8px 16px", margin: "0 4px" }}
            >
              定價與方案
            </a>
            <a
              href="https://playreal.com.tw/cases.html"
              className="w3-bar-item w3-button w3-center-a"
              style={{ padding: "8px 16px", margin: "0 4px" }}
            >
              案例
            </a>
            <a
              href="https://playreal.com.tw/support.html"
              className="w3-bar-item w3-button w3-center-a"
              style={{ padding: "8px 16px", margin: "0 4px" }}
            >
              支援
            </a>
            <a
              href="https://playreal.com.tw/services.html"
              className="w3-bar-item w3-button w3-center-a"
              style={{ padding: "8px 16px", margin: "0 4px" }}
            >
              其他服務
            </a>
          </div>
          <a
            href="javascript:void(0)"
            className=" w3-bar-item w3-button w3-left w3-hide-large w3-hide-medium"
            onClick={() => w3_open()}
            style={{
              padding: "20px",
              opacity: smallDevice == false ? "0" : "1",
            }}
          >
            <Icon icon="fa:bars" color="white" width="24" height="24" />
          </a>
          <a
            href="https://playreal.com.tw/index.html"
            className="w3-bar-item w3-button w3-hide-large w3-hide-medium w3-display-middle"
            style={{ padding: "0" }}
          >
            <img style={{ height: "60px" }} src={Icon2} />
          </a>
        </div>
      </div>
      <nav
        className="w3-sidebar w3-bar-block w3-black w3-card w3-animate-left w3-hide-medium w3-hide-large"
        style={{ display: "none" }}
        id="mySidebar"
      >
        <a
          href="javascript:void(0)"
          onClick={() => w3_close()}
          className="w3-bar-item w3-button w3-large w3-padding-16"
        >
          Close ×
        </a>
        <a
          href="https://playreal.com.tw/plans.html"
          onClick={() => w3_close()}
          className="w3-bar-item w3-button"
        >
          定價與方案
        </a>
        <a
          href="https://playreal.com.tw/cases.html"
          onClick={() => w3_close()}
          className="w3-bar-item w3-button"
        >
          案例
        </a>
        <a
          href="https://playreal.com.tw/support.html"
          onClick={() => w3_close()}
          className="w3-bar-item w3-button"
        >
          支援
        </a>
        <a
          href="https://playreal.com.tw/services.html"
          onClick={() => w3_close()}
          className="w3-bar-item w3-button"
        >
          其他服務
        </a>
      </nav>
    </div>
  );
};

export default Nav;

